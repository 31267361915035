<template>
    <div id="eight-group">
        <div class="container">
            <div class="item-wrap" v-for="(item, index) in items" :key="index">
                <EightGroupItem :data="item"></EightGroupItem>
            </div>
            <div class="item-wrap empty" v-for="(i, ind) in 3" :key="ind"></div>
        </div>
    </div>
</template>

<script>
    import EightGroupItem from '@/components/home/8groupItem'

    export default {
        name: 'EightGroup',
        components: {
            EightGroupItem,
        },
        data() {
            return {
                items: [{
                        img: require('../../assets/img/home/8group/vrbee.jpg'),
                        logo: require('../../assets/img/home/8group/vrbee-logo.png'),
                        title: 'xrBee小蜜蜂',
                        des: 'XR大空间多人全身追踪解决方案',
                        url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/vrbee_intro_0428.pdf',
                        videoUrl: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/vrbee_0428.mp4',
                        type: 'vrbee',
                        video: true,
                    },
                    {
                        img: require('../../assets/img/home/8group/octopus.jpg'),
                        logo: require('../../assets/img/home/8group/octopus-logo.png'),
                        title: 'OCTOPUS八爪鱼',
                        des: 'XR智能中控系统软件',
                        url: '/pdf/八爪鱼宣传册.pdf',
                        videoUrl: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/OCTOPUS.mp4',
                        type: 'octopus',
                        video: true,
                    },
                    /* 2012/04/20 mm disabled
                    {
                        img: require('../../assets/img/home/8group/3dcity.jpg'),
                        logo: require('../../assets/img/home/8group/3dcity-logo.png'),
                        title: '3DCITY',
                        des: 'VR模型素材库',
                        url: '/pdf/3DCITY宣传册.pdf',
                        link: 'http://www.3dcity.com',
                        videoUrl: '/video/3Dcity.mp4',
                        type: '3dcity',
                        video: true,
                    },
                    */
                    {
                        img: require('../../assets/img/home/8group/linkxr.png'),
                        logo: require('../../assets/img/home/8group/vrlink-logo.png'),
                        title: 'LinkXR',
                        des: 'XR内容适配平台',
                        url: '/pdf/LinkVR宣传册.pdf',
                        link: '',
                        videoUrl: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/LINKVR.mp4',
                        type: 'linkvr',
                        video: true,
                    },
                    {
                        img: require('../../assets/img/home/8group/flyvr.jpg'),
                        logo: require('../../assets/img/home/8group/flyvr-logo.png'),
                        title: 'FlyXR飞流',
                        des: 'XR内容串流软件',
                        url: '/pdf/FlyVR宣传页.pdf',
                        videoUrl: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/FLYVR.mp4',
                        type: 'flyvr',
                        video: true,
                    },
                    /* 2022/04/24 mm disabled
                    {
                        img: require('../../assets/img/home/8group/gmotion-bg.jpg'),
                        logo: require('../../assets/img/home/8group/g-motion.png'),
                        title: 'G-Motion',
                        des: '高精度光学位置追踪系统',
                        url: '/pdf/Gmotion宣传册.pdf',
                        video: false,
                    },
                    */
                    {
                        img: require('../../assets/img/home/8group/vrtree.jpg'),
                        logo: require('../../assets/img/home/8group/vrtree-logo.png'),
                        title: 'XR-Tree',
                        des: '多人实训交互显示产品',
                        url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/VR-TREE%E7%B3%BB%E5%88%97.pdf',
                        video: false,
                    },
                    {
                        img: require('../../assets/img/home/8group/vrgroup.jpg'),
                        logo: require('../../assets/img/home/8group/vrgroup-logo.png'),
                        title: '五星台',
                        des: '小组协同交互显示产品',
                        url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/VR-Group%E7%B3%BB%E5%88%97.pdf',
                        video: false,
                    },
                    {
                        img: require('../../assets/img/home/8group/vr2.jpg'),
                        logo: require('../../assets/img/home/8group/vr2-logo.png'),
                        title: 'XR平方',
                        des: '大屏交互显示产品',
                        url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/VR%E5%B9%B3%E6%96%B9%E7%B3%BB%E5%88%97.pdf',
                        video: false,
                    },
                    {
                        img: require('../../assets/img/home/8group/vr3.jpg'),
                        logo: require('../../assets/img/home/8group/vr3-logo.png'),
                        title: 'XR立方',
                        des: '洞穴交互显示产品',
                        url: 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/VR%E7%AB%8B%E6%96%B9%E7%B3%BB%E5%88%97.pdf',
                        video: false,
                    },
                ],
            }
        },
    }
</script>

<style scoped lang="scss">
    #eight-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin-top: 160px;
        background-color: #121212;

        .container {
            width: 1460px;
            display: flex;
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;
            margin-bottom: 110px;

            .item-wrap {
                margin: 0 10px 40px 10px;
            }

            .empty {
                width: 345px;
                height: 0;
            }

            @media screen and (max-width: 1460px) {
                & {
                    width: 1366px;
                    justify-content: center;
                }
            }
        }
    }
</style>