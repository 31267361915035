<template>
  <div id="industry">
    <div class="container">
      <div class="title">行业应用</div>
      <div class="des">
        用XR技术为教育领域、应急安全、高端工业等行业客户提供“三高四不”（高风险、高投入、高难度、不可达到、不可触及、不可重现、不可逆转）<br> 场景下的训练、培训和教学等的产品和解决方案
      </div>
      <div class="industries">
        <div class="item" v-for="(item,index) in industries" :key="index"
             :style="{backgroundImage:`url(${item.icon})`}">
          <!--          <img :src="item.icon" alt="">-->
          <div class="mask"></div>
          <div class="content">
            <p class="indus-title">{{item.title}}</p>
            <p class="indus-des">{{item.des}}</p>
            <!--            <a href="">资料下载 ></a>-->
          </div>
        </div>
        <div class="empty" v-for="(item,index) in 3" :key="index"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Industry",
  data() {
    return {
      industries: [
        {
          icon: require('../../assets/img/home/industry/jyly-bg0510-3.jpg'),
          title: '教育领域',
          des: '通过XR互动体验，增加内容的趣味性，提高理实一体化教学效率。',
          url: ''
        },
        {
          icon: require('../../assets/img/home/industry/yjaq-bg0510.jpg'),
          title: '应急安全',
          des: 'XR技术与应急安全结合，将事故现场模拟到虚拟场景中去，让学员亲自体验危险情景，加强人们对应急演练的重视。',
          url: ''
        },
        {
          icon: require('../../assets/img/home/industry/gfjg-bg0510-21.png'),
          title: '高端工业',
          des: 'XR技术广泛用于装备研发、设计评审、使用培训等高端工业领域，有助于工业人才的技能培养。',
          url: ''
        },
        /* 2022/04/25 mm disabled
        {
          icon: require('../../assets/img/home/industry/gdzz-bg.jpg'),
          title: '高端制造',
          des: 'VR技术在产品（研发、生产、培训和营销展示等）中均提供支持，对高端制造业生产结构与生产流程进行优化',
          url: ''
        },
        {
          icon: require('../../assets/img/home/industry/ylyx-bg.jpg'),
          title: '医疗医学',
          des: '随着5G发展，VR技术在医学教育、医疗培训等医学领域发挥极其重要作用',
          url: ''
        },

        {
          icon: require('../../assets/img/home/industry/yyyl-bg.jpg'),
          title: '影音娱乐',
          des: 'VR技术以其特有的沉浸感、交互性和构想性革新了影音娱乐领域, 并显著提升了用户的体验效果',
          url: ''
        },
        */
      ]
    }
  }
}
</script>

<style scoped lang="scss">
#industry {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  background-color: #222222;

  .container {
    width: 1460px;
    margin-bottom: 90px;
    @media screen and(max-width: 1460px) {
      width: 1366px;
    }

    .title {
      font-family: arial !important;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 84px;
      color: #ffffff;
    }

    .des {
      font-family: arial !important;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 192.5%;
      color: #ffffff;
      margin-top: 30px;
    }

    .industries {
      display: flex;
      flex-wrap: wrap;
      margin-top: 70px;
      margin-left: -10px;
      margin-right: -10px;

      .item {
        width: 470px;
        height: 340px;
        background-color: #fafafa;
        margin: 0 10px 60px 10px;
        //padding: 0 34px;
        position: relative;
        transition: all 0.3s;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &:hover {
          border-color: rgba(0, 0, 0, .09);
          box-shadow: 0 2px 8px rgba(0, 0, 0, .09);

          .mask {
            background: rgba(0, 0, 0, 0.4);
          }

          transform: scale(0.97);
        }

        img {
          margin-top: 80px;
        }

        .mask {
          transition: all 0.2s;
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0);
          z-index: 0;
        }

        .content {
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;

          .indus-title {
            width: 410px;
            font-family: arial !important;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            color: #ffffff;
            margin: 0 auto;
            margin-top: 230px;
            text-align: left;
          }

          .indus-des {
            width: 410px;
            font-family: Source Han Sans CN, PingFang SC, Microsoft YaHei;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            color: #ffffff;
            line-height: 24px;
            text-align: justify;
            margin: 0 auto;
            margin-top: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            //white-space: nowrap;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }

          a {
            display: inline-block;
            width: 400px;
            text-decoration: none;
            font-family: arial !important;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            /* or 120% */
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #ffffff;
            text-align: left;
            margin-top: 17px;
          }
        }
      }

      .empty {
        width: 470px;
        height: 0;
      }

      @media screen and(max-width: 1460px) {
        & {

          justify-content: center;
        }
      }
    }
  }
}
</style>
