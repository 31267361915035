<template>
  <div class="home">
    <div class="anchor-content">
      <Banner></Banner>
    </div>
    <div class="anchor-content">
      <Ideavr></Ideavr>
    </div>
    <div class="anchor-content">
      <FourGroup></FourGroup>
    </div>
    <div class="anchor-content">
      <EightGroup></EightGroup>
    </div>
    <div class="anchor-content">
      <Industry></Industry>
    </div>
    <div class="anchor-content">
      <Cases></Cases>
    </div>
    <div class="anchor-content">
      <Customers></Customers>
    </div>
    <Anchor :anchors="anchors"></Anchor>
  </div>
</template>

<script>

import Banner from "@/components/home/Banner";
import Ideavr from "@/components/home/ideavr";
import FourGroup from '@/components/home/4group'
import EightGroup from '@/components/home/8group'
import Industry from "@/components/home/Industry";
import Cases from "@/components/home/Cases";
import Customers from "@/components/home/Customers";
import Anchor from "@/components/Anchor";

export default {
  name: 'Home',
  components: {
    Banner,
    Ideavr,
    FourGroup,
    EightGroup,
    Industry,
    Cases,
    Customers,
    Anchor
  },
  data() {
    return {
      anchors: {
        count: 7,
        selector: '.anchor-content'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  height: 100%;
  overflow-y: auto;
  .anchor-content{
    overflow: hidden;
  }
}

</style>
