<template>
  <div id="customer">
    <div class="container">
      <h2 class="title">服务客户2000+</h2>
      <div class="logos">
        <div class="logo" v-for="(logo,index) in customers" :key="index">
          <div class="logoWrap">
            <img :src="logo.logo" :alt="logo.name">
          </div>
          <!--          <span class="name">{{logo.name}}</span>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Customers",
  data() {
    return {
      customers: [
        {
          logo: require('../../assets/img/home/customers/zhgy.svg'),
          name: '中航工业'
        },
        {
          logo: require('../../assets/img/home/customers/customer_zght.svg'),
          name: '中国航天'
        },
        {
          logo: require('../../assets/img/home/customers/zgh.svg'),
          name: '中广核'
        },
        {
          logo: require('../../assets/img/home/customers/zgsf.svg'),
          name: '中国商飞'
        },
        {
          logo: require('../../assets/img/home/customers/zczg.svg'),
          name: '中船重工'
        },
        {
          logo: require('../../assets/img/home/customers/customer_zgzt.svg'),
          name: '中国中铁'
        },
        {
          logo: require('../../assets/img/home/customers/zgsy.svg'),
          name: '中国石油'
        },
        {
          logo: require('../../assets/img/home/customers/gjdw.svg'),
          name: '国家电网'
        },
        {
          logo: require('../../assets/img/home/customers/bjdx.svg'),
          name: '北京大学'
        },
        {
          logo: require('../../assets/img/home/customers/qhdx.svg'),
          name: '清华大学'
        },
        {
          logo: require('../../assets/img/home/customers/customer_shjd.svg'),
          name: '上海交大'
        },
        {
          logo: require('../../assets/img/home/customers/customer_zjdx.svg'),
          name: '浙江大学'
        },
        {
          logo: require('../../assets/img/home/customers/customer_bjgz.svg'),
          name: '北京工职'
        },
        {
          logo: require('../../assets/img/home/customers/customer_cqdz.svg'),
          name: '重庆电子'
        },
        {
          logo: require('../../assets/img/home/customers/asd.svg'),
          name: '爱仕达'
        },
        {
          logo: require('../../assets/img/home/customers/customer_shjc.svg'),
          name: '上海机场'
        },
        /* 2022/04/25 mm disabled
        {
          logo: require('../../assets/img/home/customers/诺一迈尔.svg'),
          name: '诺一迈尔'
        },
        {
          logo: require('../../assets/img/home/customers/艾默生.svg'),
          name: '艾默生'
        },
        {
          logo: require('../../assets/img/home/customers/迪士尼.svg'),
          name: '迪士尼'
        },
        {
          logo: require('../../assets/img/home/customers/浙江大学.svg'),
          name: '浙江大学'
        },
        {
          logo: require('../../assets/img/home/customers/山东大学.svg'),
          name: '山东大学'
        },
        {
          logo: require('../../assets/img/home/customers/武汉大学.svg'),
          name: '武汉大学'
        },
        */
      ]
    }
  }
}
</script>

<style scoped lang="scss">
#customer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
  margin-bottom: 100px;

  .container {
    width: 1625px;
    @media screen and (max-width: 1560px) {
      width: 1460px;
    }

    .title {
      font-family: arial !important;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 123%;
      color: #ffffff;
    }

    .logos {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      //justify-content: center;

      .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 203px;
        padding-top: 60px;
        margin-bottom: 50px;

        .logoWrap {
          width: 100%;
          height: 76px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            transition: all 0.3s;

            &:hover {
              //border-color: rgba(0, 0, 0, .09);
              transform: scale(1.1);
            }
          }
        }


        .name {
          margin-top: 22px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
