<template>
    <div id="app">
        <nav id="nav">
            <div class="logo">
                <img src="./assets/img/logo.png" alt="GDI" />
            </div>
            <ul class="tabs">
                <router-link to="/" tag="li">
                    <span class="route-name">首页</span>
                    <span class="active-bar"></span>
                </router-link>
                <router-link to="about" tag="li">
                    <span class="route-name">关于曼恒</span>
                    <span class="active-bar"></span>
                </router-link>
                <router-link to="contact" tag="li">
                    <span class="route-name">联系我们</span>
                    <span class="active-bar"></span>
                </router-link>
            </ul>
            <div class="phone">
                <img src="./assets/img/phone.png" class="phone-icon" alt="phone" />
                <span class="phone-num">4008 233 766</span>
            </div>
        </nav>
        <main id="main">
            <keep-alive>
                <router-view />
            </keep-alive>
        </main>
        <footer id="footer">
            <img src="./assets/img/footer.png" class="footer-icon" alt="GDI" />
            <p class="scan-des">扫码关注公众号</p>
            <p class="copyright">
                <a href="https://beian.miit.gov.cn/" target="_blank">
                    沪ICP备08005943号-2 ©
                </a>

                2022 曼恒数字
            </p>
        </footer>
        <OpenShow v-if="openShow"></OpenShow>
    </div>
</template>
<script>
    import OpenShow from '@/components/OpenShow'

    export default {
        name: 'App',
        components: {
            OpenShow,
        },
        data() {
            return {
                openShow: true,
            }
        },
    }
</script>
<style lang="scss">
    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #555555;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        background-color: #121212;
        position: relative;
    }

    #nav {
        width: 100%;
        height: 72px;
        padding-left: 33px;
        padding-right: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #232524;
        position: relative;

        .logo {
            width: 126px;
            position: absolute;
            left: 33px;
        }

        .tabs {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            align-self: stretch;
            display: flex;
            justify-content: center;

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                //padding: 0 28px;
                cursor: pointer;
                transition: all 0.3s ease;
                color: #ffffff;
                position: relative;
                flex-direction: column;

                &:hover {
                    background-color: #4d4f4d;
                }

                .active-bar {
                    position: absolute;
                    bottom: 0;
                    width: 0;
                    height: 3px;
                    background-color: #f89227;
                    transition: all 0.5s linear;
                }

                &.router-link-active.router-link-exact-active {
                    background-color: #1a1c1a;

                    .active-bar {
                        width: 100%;
                    }
                }

                .route-name {
                    padding: 0 28px;
                }
            }
        }

        .phone {
            position: absolute;
            right: 40px;
            font-family: PingFang HK;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            display: flex;
            align-items: center;

            .phone-icon {
                margin-right: 18px;
                width: 20px;
            }

            .phone-num {
                color: #ffffff;
            }
        }
    }

    #main {
        flex: 1;
        overflow-y: auto;
    }

    #footer {
        width: 100%;
        height: 164px;
        background-color: #222222;
        position: relative;

        .footer-icon {
            width: 60px;
            margin-top: 20px;
        }

        .scan-des {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #ffffff;
            letter-spacing: 3px;
            margin-top: 10px;
        }

        .copyright {
            font-family: arial !important;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: #ffffff;
            position: absolute;
            bottom: 20px;
            width: 100%;
        }
    }
</style>
