<template>
  <div id="openContainer">
    <div class="content">
      <div class="text">
        <span class="step1 animate__animated animate__fadeInUp">把世界</span>
        <span class="step2 animate__animated animate__fadeInUp ">带到</span>
        <span class="step3 animate__animated animate__fadeInUp ">你眼前</span>
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OpenShow",
  mounted() {
    document.body.style.overflow = 'hidden'
    this.enableBodyScroll()
  },
  methods: {
    enableBodyScroll() {
      setTimeout(() => {
        document.body.style.overflow = ''
      }, 5000)
    }
  },
  destroyed() {
    document.body.style.overflow = ''
  }
}
</script>

<style scoped lang="scss">
#openContainer {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #232524;
  z-index: 10000000;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: windowClose 400ms ease-in 3.1s 1 alternate forwards;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    animation: contentUp 500ms ease-in 3s 1 alternate forwards;

    .text {
      display: flex;
      justify-content: center;
      margin-left: 20px;

      span {
        display: inline-block;
        font-family: Arial;
        color: #ffffff;
        font-size: 18px;
        letter-spacing: 20px;
        text-align: center;
      }

      .step1 {
        animation-delay: 1.5s;
        animation-duration: 600ms;
      }

      .step2 {
        animation-delay: 1.5s;
        animation-duration: 600ms;
      }

      .step3 {
        animation-delay: 1.5s;
        animation-duration: 600ms;
      }
    }

    .line {
      //width: 400px;
      height: 2px;
      background-color: #f89227;
      margin-top: 17px;
      animation: widthOpen 350ms ease-out 1s 1 alternate forwards;
    }
  }
}

@keyframes contentUp {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}

@keyframes widthOpen {
  0% {
    width: 0;
  }
  100% {
    width: 400px;
  }
}

@keyframes windowClose {
  0% {
    height: 100vh;
  }
  100% {
    height: 0;
  }
}
</style>
