<template>
    <div id="case">
        <div class="container">
            <h2 class="title">客户与案例</h2>
            <p class="des">积累了国内外2000+知名企业、高端工业、高校、职校和三甲医院等企事业单位，拥有行业前沿的应用案例</p>
            <div class="swiper-wrap">
                <swiper class="swiper" :options="swiperOption">
                    <swiper-slide v-for="(slide, index) in slides" :key="index" class="slide">
                        <div class="left">
                            <img :src="slide.img" alt="" />
                        </div>
                        <div class="right">
                            <h2 class="title">{{ slide.title }}</h2>
                            <h3 class="subtitle">{{ slide.subtitle }}</h3>
                            <p class="des">{{ slide.des }}</p>
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
                    <div class="prev" slot="button-prev">
                        <img src="../../assets/img/home/cases/left-circle.png" alt="" />
                    </div>
                    <div class="next" slot="button-next">
                        <img src="../../assets/img/home/cases/right-circle.png" alt="" />
                    </div>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Cases',
        data() {
            return {
                swiperOption: {
                    autoplay: {
                        delay: 5000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false,
                    },
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'fraction',
                    },
                    navigation: {
                        nextEl: '.next',
                        prevEl: '.prev',
                    },
                },
                slides: [
                    {
                        img: require('../../assets/img/home/cases/case_bjdx.png'),
                        title: '北京大学',
                        subtitle: '沉浸式XR实验教学中心',
                        des: '曼恒数字为北京大学地球与空间科学学院建设了沉浸式XR实验中心，这是国家较早一批建设的国家级虚拟仿真实验教学中心。',
                    },
                    {
                        img: require('../../assets/img/home/cases/case_jtdx.png'),
                        title: '上海交通大学',
                        subtitle: 'XR创新教育实验中心',
                        des: '"上海交大-XR创新教育实验中心”由上海交大文创学院和曼恒数字联合建设。中心具备教育创新实验室、创新技术培训课程、创新应用与内容创作工作室三大职能。同时，双方将共同打造XR课程，持续推进虚拟现实技术在教育教学领域的应用。',
                    },
                    {
                        img: require('../../assets/img/home/cases/case_mkdh.png'),
                        title: '中国慕课大会',
                        subtitle: 'XR+5G技术与高校实验教学',
                        des: '曼恒自主研发XR内容创作引擎IdeaXR，支持快速构建多人异地协同工作环境。专注于解决用户在“异地多人协同”场景下的协同培训，实现高效的沟通和“教、学、考、练”环节全覆盖；同时为高校教学实验实训提供技术支撑，推动高等教育内涵式发展。',
                    },
                    {
                        img: require('../../assets/img/home/cases/case_dqyt.png'),
                        title: '大庆油田',
                        subtitle: '应急演练XR培训系统',
                        des:
                            '在国家危险化学品应急救援实训大庆基地，依托曼恒数字自主研发的虚拟现实内容创作引擎IdeaXR，建成了“大庆油田应急演练-XR培训系统”，既降低了培训成本，又大大提高了演练效率。',
                    },
                    {
                        img: require('../../assets/img/home/cases/case_pdjc.jpg'),
                        title: '上海浦东机场',
                        subtitle: '消防应急救援指挥视觉模拟系统',
                        des:
                            '项目结合上海机场集团在机场应急保障方面的先进经验和做法，利用GIS(地理信息系统)、3D建模、XR和5G通信等高新技术，通过沉浸式和多人协同的培训手段，从方案编制、作业流程、案例体验、技能考评等方面对业务人员进行全方位的实训和考核。',
                    },
                    // {
                    //     img: require('../../assets/img/home/cases/case_htmy.png'),
                    //     title: '中国航天某院',
                    //     subtitle: '防空导弹XR模拟系统',
                    //     des:
                    //         '该系统一改传统的军事训练，特别是大杀伤性武器的军事演习的缺点，如：消耗大量资金和物资，安全性难以保证等。并且该系统可以在实战演习条件下改变状态，来反复进行各种战场态势下的战术和决策研究。'
                    // },
                    {
                        img: require('../../assets/img/home/cases/case_c919.jpg'),
                        title: '中国商飞',
                        subtitle: '为国产大飞机C919试飞保驾护航',
                        des:
                            '曼恒数字为商飞试飞中心提供机载测试系统地面验证平台，对实际飞机中飞行的参数进行监控分析，确保飞机在预飞和实际飞行中相关参数的准确和正常，为C919成功首飞提供了坚实的数据保障。',
                    },
                    /* 2022/04/25 mm disabled
                    {
                        img: require('../../assets/img/home/cases/上海电力股份有限公司.jpg'),
                        title: '上海电力股份有限公司',
                        subtitle: '大丰海上风电VR（虚拟现实）交互系统',
                        des:
                            '构建风力发电机设备操作模拟仿真环境，在减少危险的前提下进行高仿真虚拟培训，通过沉浸式培训模式对操作人员进行全系统突破时空限制，降低培训成本，提高培训效率，保障安全生产',
                    },
                    */
                    {
                        img: require('../../assets/img/home/cases/case_ams.png'),
                        title: '艾默生',
                        subtitle: '亚太异地虚拟协同演示平台',
                        des:
                            '艾默生是一家拥有百年历史的世界500强企业，曼恒数字为其打造了亚太区虚拟现实体验中心，以“IdeaXR异地虚拟协同演示平台”为核心，帮助艾默生为客户用异地虚拟协同的创新方式展示设计方案。',
                    },
                    /* 2022/04/25 mm disabled
                    {
                        img: require('../../assets/img/home/cases/英特格拉.jpg'),
                        title: '英特格拉',
                        subtitle: 'ICP颅内压监测探头植入VR教学培训系统',
                        des:
                            '美国英特格拉生命科学是全球神经外科医疗器械供应商，曼恒与其联合推出的ICP颅内压监测探头植入VR教学培训系统，极大突破传统临床培训局限，让颅内压监测探头植入操作培训更加便捷、有效',
                    },
                    */
                ],
            }
        },
    }
</script>

<style scoped lang="scss">
    #case {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 100px;

        .container {
            width: 1460px;
            @media screen and (max-width: 1460px) {
                width: 1366px;
                padding: 0 15px;
            }

            .title {
                font-family: arial !important;
                font-style: normal;
                font-weight: bold;
                font-size: 48px;
                color: #ffffff;
            }

            .des {
                font-family: arial !important;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 192.5%;
                color: #ffffff;
                margin-top: 30px;
            }

            .swiper-wrap {
                width: 100%;
                height: auto;
                margin-top: 70px;
                position: relative;
                transition: all 0.3s;
                background-color: #292929;

                &:hover {
                    border-color: rgba(0, 0, 0, 0.09);
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
                }

                .swiper {
                    height: 100%;

                    .slide {
                        display: flex;

                        .left {
                            flex: 1 0 50%;
                        }

                        .right {
                            flex: 1 0 50%;
                            text-align: left;
                            padding-left: 88px;
                            //background-color: #FBFBFB;

                            .title {
                                font-family: arial !important;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 36px;
                                line-height: 123%;
                                color: #ffffff;
                                margin-top: 50px;
                            }

                            .subtitle {
                                font-family: arial !important;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 24px;
                                line-height: 123%;
                                color: #ffffff;
                                margin-top: 10px;
                            }

                            .des {
                                width: 540px;
                                font-family: arial !important;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 18px;
                                line-height: 36px;
                                color: #ffffff;
                                margin-top: 36px;
                            }
                        }
                    }

                    .swiper-pagination {
                        font-family: Arial;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 28px;
                        line-height: 120%;
                        color: #aaaaaa;
                        width: 215px;
                        left: calc(50% + 88px);
                        bottom: 58px;
                        @media screen and(max-width: 1460px) {
                            bottom: 66px;
                        }
                    }

                    .prev {
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        //border: 2px solid #AAAAAA;
                        left: calc(50% + 88px);
                        bottom: 50px;
                        z-index: 10;
                        cursor: pointer;
                        opacity: 0.85;

                        &:hover {
                            opacity: 1;
                        }
                        @media screen and(max-width: 1460px) {
                            bottom: 60px;
                        }
                    }

                    .next {
                        @extend .prev;
                        left: calc(50% + 255px);
                        z-index: 10;
                    }
                }
            }
        }
    }
</style>
