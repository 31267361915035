<template>
  <div class="anchors">
    <div class="anchor-outer" v-for="(anchor,index) in anchors.count" :key="index" @click="scrollTo(index)">
      <div class="anchor-inner" :class="{active:active===index}"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Anchor",
  props: {
    anchors: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      active: 0
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  methods: {
    scrollTo(index) {
      // 获取目标元素 offsetTop
      const targetOffetTop = document.querySelector(`${this.anchors.selector}:nth-child(${index + 1})`).offsetTop;
      // 获取文档流scrollTop
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // 定义一次跳50px
      const step = 100
      // 判断是上滑还是下滑
      if (scrollTop >= targetOffetTop) {
        // 上滑
        smoothUp()
      } else {
        // 下滑
        smoothDown()
      }

      function smoothUp() {
        if (scrollTop > targetOffetTop) {
          if (scrollTop - targetOffetTop >= step) {
            scrollTop -= step
          } else {
            scrollTop = targetOffetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          requestAnimationFrame(smoothUp)
        }
      }

      function smoothDown() {
        if (scrollTop < targetOffetTop) {
          if (targetOffetTop - scrollTop >= step) {
            scrollTop += step
          } else {
            scrollTop = targetOffetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          requestAnimationFrame(smoothDown)
        }
      }

    },
    onScroll() {
      // 获取所有锚点元素
      const contents = document.querySelectorAll(this.anchors.selector);
      // 获取所有锚点元素的 offsetTop
      let offsetTopArr = []
      contents.forEach((item) => {
        offsetTopArr.push(item.offsetTop)
      })
      // 获取当前文档流的 scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // 定义当前点亮的导航下标
      let navIndex = 0
      for (let n = 0; n < offsetTopArr.length; n++) {
        if (scrollTop >= offsetTopArr[n] - 60) {
          navIndex = n
        }
      }
      this.active = navIndex
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style scoped lang="scss">

.anchors {
  position: fixed;
  right: 30px;
  top: 50%;
  width: 32px;
  height: auto;
  z-index: 100;

  .anchor-outer {
    width: 32px;
    height: 32px;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .anchor-inner {
      width: 14px;
      height: 14px;
      background: rgba(238, 238, 238, 0.3);;

      &.active {
        background-color: #F89227;
      }
    }

  }
}

</style>
