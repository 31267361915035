<template>
    <div id="ideavr">
        <div class="others">
            <div class="logo-title">
                <img class="ideavr-logo" src="../../assets/img/home/ideavr/ideavr-logo.png" alt="" />
                <p class="title">IdeaXR </p>
                <!--        <span class="afanda">阿凡达</span>-->
            </div>
            <p class="subtitle">国产自主研发XR引擎 拥有完全知识产权</p>
            <p class="des">
                IdeaXR内容创作引擎是一款为教育、企业等行业领域打造的XR引擎开发工具。拥有跨平台（支持国产系统）和易学易用的特性，提供快速搭建场景、图形化交互编辑、多人协同演练等功能，可应用于教育、军工、应急等行业领域。
            </p>
            <!--      <img class="editor" src="../../assets/img/home/ideavr/editor.png" alt="">-->
            <div class="btns">
                <div class="download try" style="border:none; margin-left: 0; margin-right: 40px;">
                    <img src="../../assets/img/video.svg" alt="" />
                    <span @click.stop="viewVideo">观看视频</span>
                </div>
                <a href="https://apply.ideavr.top/site/register" target="_blank">
                    <div class="download" >
                        <div class="mask"></div>
                        <img src="../../assets/img/home/ideavr/mor-icon.svg" alt="" />
                        <span>申请试用</span>
                    </div>
                </a>
            </div>
        </div>
        <div class="model-container">
            <iframe
                title="3D Model"
                id="model-view"
                class="hero__iframe"
                width="auto"
                height="auto"
                :src="url"
                frameborder="0"
                allow="autoplay; fullscreen; vr"
                allowvr=""
                allowfullscreen=""
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
                onmousewheel=""
            ></iframe>
        </div>
        <!-- <div class="mask-top"></div>
        <div class="mask-bottom"></div> -->
    </div>
</template>

<script>
    export default {
        name: 'Ideavr',
        data() {
            return {
                url: `https://sketchfab.com/models/9688985db84d447580d40e40e1649407/embed?transparent=1&autostart=1&internal=1
                &annotations_visible=0&autospin=-0.1&camera=0
                &preload=1
                &transparent=1&ui_animations=0&ui_annotations=0&ui_ar=0&ui_ar_help=0&ui_color=white&ui_fadeout=0&ui_fullscreen=0&ui_help=0&ui_infos=0&ui_inspector=0&ui_settings=0&ui_stop=0&ui_theatre=0&ui_theme=dark&ui_vr=0&ui_watermark=0&orbit_constraint_pan=1&orbit_constraint_zoom_in=10&orbit_constraint_zoom_out=80&preload=1&scrollwheel=1sound_enable=0&tracking=0
                `,
            }
        },
        methods: {
            viewVideo() {
                layui.use('layer', function() {
                    const layer = layui.layer
                    layer.open({
                        type: 1,
                        title: false,
                        area: '60%',
                        scrollbar: false,
                        content: `<video autoplay controls src="https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/IdeaVR.mp4" style="width: 100%;outline: none">`,
                    })
                })
            },
        },
    }
</script>

<style scoped lang="scss">
    #ideavr {
        background-color: #121212;
        height: 1035px;
        overflow: hidden;
        background-image: url('../../assets/img/home/ideavr/bg.png');
        background-repeat: no-repeat;
        background-size: 56.51%;
        background-position: 667px 137px;
        position: relative;
        max-width: 1920px;
        margin: 0 auto;

        .others {
            text-align: left;
            //margin-top: 162px;
            //margin-left: 280px;
            position: absolute;
            top: 280px;
            left: 280px;
            z-index: 1;
            @media screen and (max-width: 1560px) {
                left: 100px;
            }

            .logo-title {
                width: 100%;
                display: flex;
                align-items: center;

                .title {
                    font-family: Arial;
                    font-style: normal;
                    font-weight: bolder;
                    font-size: 64px;
                    color: #ffffff;
                    margin-left: 28px;
                }

                .afanda {
                    font-family: arial !important;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 28px;
                    color: #ffffff;
                    margin-top: 20px;
                    margin-left: 15px;
                }
            }

            .subtitle {
                font-family: arial !important;
                font-style: normal;
                // font-weight: bold;
                font-size: 28px;
                color: #ffffff;
                margin-top: 28px;
            }

            .des {
                width: 770px;
                font-family: arial !important;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 180%;
                color: #ffffff;
                margin-top: 8px;
                text-align: justify;
                @media screen and(max-width: 1560px) {
                    width: 550px;
                }
            }

            .editor {
                max-width: 100%;
                margin-top: 24px;
                position: relative;
                left: -16px;
            }

            .btns {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-top: 80px;

                .download {
                    width: 200px;
                    height: 48px;
                    background: transparent;
                    border-radius: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid #bbbbbb;
                    box-sizing: border-box;
                    cursor: pointer;
                    transition: all 0.2s;
                    //&:hover {
                    //  opacity: 0.95;
                    //}
                    &:hover {
                        border: 2px solid #ffffff;
                    }

                    &:active {
                        border: 2px solid #bcbcbc;
                        opacity: 0.8;
                    }

                    img {
                        width: 16px;
                    }

                    span {
                        margin-left: 9px;
                        font-family: arial !important;
                        font-style: normal;
                        //font-weight: 500;
                        font-size: 18px;
                        color: #ffffff;
                    }
                }

                .try {
                    position: relative;
                    width: 200px;
                    height: 48px;
                    background: linear-gradient(315.22deg, #5d9134 23.66%, #a8cd5e 76.46%);
                    border-radius: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    transition: all 0.2s ease;
                    margin-left: 40px;

                    .mask {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        border-radius: 100px;
                        transition: all 0.2s;
                        background: rgba(0, 0, 0, 0);
                    }

                    img {
                        width: 16px;
                    }

                    &:hover {
                        .mask {
                            background: rgba(255, 255, 255, 0.2);
                        }
                    }

                    &:active {
                        .mask {
                            background: rgba(0, 0, 0, 0.2);
                        }
                    }

                    //&:hover {
                    //  opacity: 0.95;
                    //}

                    span {
                        margin-left: 9px;
                        font-family: arial !important;
                        font-style: normal;
                        //font-weight: 500;
                        font-size: 18px;
                        color: #ffffff;
                    }
                }
            }
        }

        .model-container {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            max-height: 1035px;
            z-index: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            overflow: hidden;

            #model-view {
                margin: -50px 0;
                border: none;
                width: 50%;
                height: 100%;
            }
        }

        .mask-top {
            width: 100%;
            height: 60px;
            background-color: #121212;
            position: absolute;
            top: 0;
            z-index: 2;
        }

        .mask-bottom {
            width: 100%;
            height: 60px;
            background-color: #121212;
            position: absolute;
            bottom: 0;
            z-index: 2;
        }
    }
</style>
