<template>
  <div id="four-group">
    <section class="sec 7guang">
      <img class="left" src="../../assets/img/home/4group/7guang.jpg" alt=""/>
      <div class="right">
        <div class="logo">
          <img src="../../assets/img/home/4group/7guang-logo.svg" style="width:130px" alt=""/>
          <div class="titles">
            <span class="big-title">MR一体机</span>
            <span class="small-title">面向B端行业市场的专业MR头盔</span>
          </div>
        </div>
        <!--        <img src="../../assets/img/home/4group/g-motion.png" alt="">-->
        <p class="des">
          作为一款面向B端行业版的全新MR一体机，7光搭载高通骁龙XR2平台，采用CV头手6DOF光学定位系统，实现真4K高清显示。
          标配8GB+512GB大容量内存，支持58-72mm范围IPD瞳距连续调节。
          以其强悍的硬件性能，自然流畅的操控体验，为行业用户带来更真实的沉浸感和卓越的体验感。
        </p>
        <div class="btns">
          <div class="btn download seven" style="margin-left: 0;">
            <img src="../../assets/img/video.svg" alt=""/>
            <span @click="viewVideo('7guang')">观看视频</span>
          </div>
          <!-- <div class="btn download seven">
            <img src="../../assets/img/download-icon.png" alt=""/>
            <span>
              <a href="https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/%E6%9B%BC%E6%81%927%E5%85%89VR%E4%B8%80%E4%BD%93%E6%9C%BA%E4%BA%A7%E5%93%81%E4%BB%8B%E7%BB%8D.jpg" target="_blank" download="曼恒7光VR一体机产品介绍.jpg">
              资料下载
              </a>
            </span>
          </div> -->
        </div>
      </div>
    </section>
    <section class="sec g-motion">
  <img class="left" src="../../assets/img/home/4group/gmotion-bg.jpg" alt="">
  <div class="right">
    <div class="logo">
      <img src="../../assets/img/home/4group/g-motion.png" alt="">
      <div class="titles">
        <span class="big-title">G-Motion5.0</span>
        <span class="small-title">XR人机交互动作捕捉定位光学系统</span>
      </div>
    </div>
    <!--
    <img src="../../assets/img/home/4group/g-motion.png" alt="">
    -->
    <p class="des">
      新一代GM 5.0追踪系统，是一款主动式亚毫米级的XR交互追踪。创新性的主动式摄像头安装在运动点，主动拍摄固定光学成像点位，目标更清晰，抗干扰能力强，时延低，精度高，运算量小。
      结合LinkXR及双手柄操作，用户可以更自然简便的方式体验XR内容。
    </p>
    <div class="btns">
      <!-- <div class="btn download gmotion">
        <img src="../../assets/img/download-icon.png" alt="">
        <span><a href="https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/G-MotionV50.pdf" target="_blank" download="曼恒G-Motion5.0介绍2022.pptx">资料下载</a></span>
      </div> -->
    </div>
  </div>
    </section>
    <!-- 2022/04/24 mm disabled start
    <section class="sec vrdo">
      <img class="left" src="../../assets/img/home/4group/vrdo-bg.jpg" alt=""/>
      <div class="right">
        <div class="logo">
          <img src="../../assets/img/home/4group/vrdo.png" alt=""/>
          <div class="titles">
            <span class="big-title">VRDO</span>
            <span class="small-title">工程技术领域专用VR引擎</span>
          </div>
        </div>
        2022/04/24 mm disabled end -->
        <!--        <img src="../../assets/img/home/4group/vrdo.png" alt="">-->
    <!-- 2022/04/24 mm disabled start
    <p class="des">
      高效整合行业数据，快速搭建工业场景；帮助机械、自动化等用户产品教学，同时也为高端制造业客户提供产品展示、设计评审、客户培训、市场宣传和数字化样机设计等应用
    </p>
<div class="btns">
2022/04/24 mm disabled end -->
<!--          <div class="btn download">-->
<!--            <img src="../../assets/img/video.svg" alt=""/>-->
<!--            <span @click="viewVideo('vrdo')">观看视频</span>-->
<!--          </div>-->
<!--          <a href="https://app.shb.ltd/p/109299#/login?redirect=%2FchooseEvent" target="_blank">-->
<!--            <div class="btn try vrdo">-->
<!--              <img src="../../assets/img/try-logo.png" alt=""/>-->
<!--              <span>申请试用</span>-->
<!--              <div class="mask"></div>-->
<!--            </div>-->
<!--          </a>-->
    <!-- 2022/04/24 mm disabled start
        </div>
      </div>
    </section>
    2022/04/24 mm disabled end -->
    <section class="sec commander">
      <img class="left" src="../../assets/img/home/4group/commander-bg.jpg" alt=""/>
      <div class="right">
        <div class="logo">
          <img src="../../assets/img/home/4group/commander.png" alt=""/>
          <div class="titles">
            <span class="big-title">Commander</span>
            <span class="small-title">XR数字化模拟训练系统</span>
          </div>
        </div>
        <p class="des">
          Commander（指挥官）是曼恒推出的应急安全领域进行模拟演练平台产品，针对高复杂、高投入、高危险、技术密集的行业领域，以XR技术搭建场景，定义训练流程脚本，设定训练目标和考核要求，让学员进入虚拟场景完成现实中难以实现的训练任务。该产品广泛用于石油石化，矿山码头，机场医院等客户群体，大大的降低客户日常演练的成本，提高演练的难度和灵活度。
        </p>
        <div class="btns">
          <div class="btn download commander" style="margin-left: 0;">
            <img src="../../assets/img/video.svg" alt=""/>
            <span @click="viewVideo('Commander')">观看视频</span>
          </div>
          <!-- <div class="btn download commander">
            <img src="../../assets/img/download-icon.png" alt=""/>
            <span>
              <a href="https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/Commander.pdf" target="_blank" download="指挥官案例介绍.pdf">
              资料下载
              </a>
            </span>
          </div> -->
        </div>
      </div>
    </section>
    <!-- 2022/04/24 mm disabled start
    <section class="sec vrbee">
      <img class="left" src="../../assets/img/home/4group/vrbee-bg.jpg" alt=""/>
      <div class="right">
        <div class="logo">
          <img src="../../assets/img/home/4group/vrbee.png" alt=""/>
          <div class="titles">
            <span class="big-title">vrBee</span>
            <span class="small-title">新一代VR大空间解决方案</span>
          </div>
        </div>
        2022/04/24 mm disabled end -->
        <!--        <img src="../../assets/img/home/4group/vrbee.png" alt="">-->
    <!-- 2022/04/24 mm disabled start
    <p class="des">
      方案包括了交互追踪系统，头戴显示系统，图形渲染系统，无线推流系统，内容播控系统，以及VR引擎六大系统；能够给予用户从案例制作，内容使用管理，无线多人协同，大空间沉浸式体验的一站式解决方案
    </p>
    <div class="btns">
    2022/04/24 mm disabled end -->
<!--          <div class="btn download">-->
<!--            <img src="../../assets/img/video.svg" alt=""/>-->
<!--            <span @click="viewVideo('vrbee')">观看视频</span>-->
<!--          </div>-->
    <!-- 2022/04/24 mm disabled start
        </div>
      </div>
    </section>
    2022/04/24 mm disabled end -->
    <section class="sec vrbox">
      <img class="left" src="../../assets/img/home/4group/vrbox-bg.jpg" alt=""/>
      <div class="right">
        <div class="logo">
          <img src="../../assets/img/home/4group/vrbox.png" alt=""/>
          <div class="titles">
            <span class="big-title">XRBOX</span>
            <span class="small-title">XR内容平台</span>
          </div>
        </div>
        <!--        <img src="../../assets/img/home/4group/vrbox.png" alt="">-->
        <p class="des vrbox-des">
          面向行业用户的XR内容平台，链接教育用户和优秀内容开发 者，帮助本科、高校、职校等用户解决优质XR内容稀缺、内容
          获取渠道有限、内容标准不一、设备之间互联互通困难等现实问题。
        </p>
        <div class="vrbox-extra">
          <p>
            <span>3800+</span>
            <span>XR课程资源</span>
          </p>
          <p>
            <span>200+</span>
            <span>专业类别</span>
          </p>
          <p>
            <span>100+</span>
            <span>系列</span>
          </p>
        </div>
        <div class="btns">
          <div class="btn try vrbox" style="margin-left: 0; margin-right: 40px;">
            <img src="../../assets/img/video.svg" alt=""/>
            <span @click="viewVideo('vrbox')">观看视频</span>
          </div>
          <a href="https://www.gvrbox.com" target="_blank">
            <div class="btn download ">
              <img src="../../assets/img/home/4group/vrbox-try.png" alt=""/>
              <span>立即体验</span>
              <div class="mask"></div>
            </div>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'fourGroup',
  methods: {
    viewVideo(type) {
      let src = ''
      switch (type) {
        case '7guang':
          src = 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/7guang.mp4'
          break
        case 'vrdo':
          src = 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/VRDO.mp4'
          break
        case 'vrbee':
          src = 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/VRBEE.mp4'
          break
        case 'vrbox':
          src = 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/VRBOX.mp4'
          break
        case 'Commander':
          src = 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/Commander.mp4'
          break
        default:
          src = 'https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/VRBOX.mp4'
      }
      layui.use('layer', function () {
        const layer = layui.layer
        layer.open({
          type: 1,
          title: false,
          area: '60%',
          scrollbar: false,
          content: `<video autoplay controls src="${src}" style="width: 100%;outline: none">`,
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
#four-group {
  background-color: #121212;
  //margin-top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .sec {
    max-width: 1920px;
    min-width: 1366px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      width: 50%;
    }

    .right {
      text-align: left;
      width: 50%;

      .logo {
        display: flex;
        align-items: center;

        img {
          max-width: 100%;
        }

        .titles {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 26px;

          .big-title {
            font-family: arial !important;
            font-style: normal;
            font-weight:bolder;
            font-size: 48px;
            letter-spacing: 0.05em;
            line-height: 100%;
            color: #ffffff;
          }

          .small-title {
            font-family: arial !important;
            font-style: normal;
            // font-weight: bold;
            font-size: 28px;
            /* or 37px */
            letter-spacing: 0.08em;
            color: #ffffff;
          }
        }
      }

      .des {
        max-width: 585px;
        margin-top: 40px;
        font-family: arial !important;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 180%;
        letter-spacing: 2px;
        color: #ffffff;
        text-align: justify;
        @media screen and (max-width: 1460px) {
          max-width: 500px;
        }
      }

      .vrbox-extra {
        display: flex;
        align-items: center;
        margin-top: 40px;

        p {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 60px;

          span:first-child {
            font-family: arial !important;
            font-style: normal;
            font-weight: bold;
            font-size: 38px;
            color: #ffffff;
          }

          span:nth-child(2) {
            font-family: arial !important;
            font-style: normal;
            font-size: 18px;
            color: #ffffff;
            font-weight: bold;
          }
        }
      }

      .btns {
        margin-top: 70px;
        display: flex;
        align-items: center;

        .download {
          flex: none;
          width: 200px;
          height: 46px;
          background: transparent;
          border: 2px solid #bbbbbb;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.2s;

          &:hover {
            border: 2px solid #ffffff;
          }

          &:active {
            border: 2px solid #bcbcbc;
            opacity: 0.8;
          }

          img {
            width: 16px;
          }

          span {
            margin-left: 16px;
            font-family: arial !important;
            font-style: normal;
            //font-weight: 500;
            font-size: 18px;
            color: #ffffff;
          }

          &.seven {
            margin-left: 40px;
            border: none;
            background: linear-gradient(to right, rgb(201, 85, 255), rgb(0, 227, 219));

            &:hover {
              background: linear-gradient(to right, rgba(201, 85, 255, 0.9), rgba(0, 227, 219, 0.9));
            }
          }

          &.gmotion {
            border: none;
            background: linear-gradient(rgba(104, 189, 133, 1), rgba(103, 199, 170, 1),rgba(72, 195, 198, 1));

            &:hover {
              background: linear-gradient(rgba(104, 189, 133, 0.9), rgba(103, 199, 170, 0.9),rgba(72, 195, 198, 0.9));
            }
          }

          &.commander {
            margin-left: 40px;
            border: none;
            background: linear-gradient(rgba(237, 128, 93, 1), rgba(204, 75, 108, 1),rgba(126, 63, 138, 1));

            &:hover {
              background: linear-gradient(rgba(237, 128, 93, 0.9), rgba(204, 75, 108, 0.9),rgba(126, 63, 138, 0.9));
            }
          }
        }

        .try {
          width: 220px;
          height: 50px;
          background: #333333;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.2s ease;
          margin-left: 40px;
          position: relative;

          .mask {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 100px;
            transition: all 0.2s;
            background: rgba(0, 0, 0, 0);
          }

          &:hover {
            .mask {
              background: rgba(255, 255, 255, 0.2);
            }
          }

          &:active {
            .mask {
              background: rgba(0, 0, 0, 0.2);
            }
          }

          //&:hover {
          //  opacity: 0.95;
          //}

          &.vrdo {
            background: linear-gradient(311.6deg, #3a7179 -0.61%, #a2d1d9 104.67%);
          }

          &.vrbox {
            background: linear-gradient(321.22deg, #652dbf 25.95%, #ac43f2 83.29%);
          }

          span {
            margin-left: 16px;
            font-family: arial !important;
            font-style: normal;
            //font-weight: 500;
            font-size: 18px;
            color: #ffffff;
          }
        }
      }
    }

    &:not(:first-child) {
      margin-top: 110px;
    }

    &:nth-child(odd) {
      .left {
        margin-left: 0;
        order: 1;
      }

      .right {
        margin-left: 110px;
        order: 2;
      }
    }

    &:nth-child(even) {
      .left {
        margin-left: 110px;
        order: 2;
      }

      .right {
        margin-left: 150px;
        order: 1;
      }
    }
  }

  .g-motion {
    //width: 1460px;
  }
}
</style>
