<template>
  <div class="banner">
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide class="banner-slide" v-for="(slide,index) in slides" :key="index"
                    :style="{backgroundImage:`url(${slide})`}">
        <!--        <img :src="slide" class="slide-img">-->
      </swiper-slide>
      <!--      <div class="swiper-pagination" slot="pagination"></div>-->
      <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>

export default {
  name: "Banner",
  data() {
    return {
      slides: [
        require('../../assets/img/home/banner/ba1.jpg'),
        require('../../assets/img/home/banner/ba2.jpg'),
        require('../../assets/img/home/banner/ba3.jpg'),
        // require('../../assets/img/home/banner/ba5.jpg'),
        // require('../../assets/img/home/banner/ba4.jpg'),
      ],
      swiperOptions: {
        preventClicks: false,
        loop: true,
        // effect: 'fade',
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: (index, className) => {
            // return `<span class="${className} swiper-pagination-bullet-custom"><img src="${this.slides[index]}" alt=""></span>`
            return `<span class="${className} swiper-pagination-bullet-custom"></span>`
          }
        },
        fadeEffect: {
          crossFade: true
        },
        autoplay: {
          delay: 7000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        }
      }
    }
  },
  computed: {},
  methods: {
    link() {  // if (index === 0) {
      //   window.open('http://ideavr.top/avatar/', "_blank")
      // }

    },
    onSwiper() {
    },
    onSlideChange() {
    }
  }
}
</script>

<style lang="scss">
.banner {
  width: 100%;
  min-width: 1500px;
  height: 780px;
  overflow: hidden;
  background-color: #FFFFFF;

  .swiper-container {
    height: 100%;

    .banner-slide {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      //cursor: pointer;
    }
  }

  .swiper-pagination-bullet-custom {
    width: 42px !important;
    height: 7px;
    //height: 73px !important;
    //line-height: 73px !important;
    border-radius: 0;
    text-align: center;
    //color: rgba(0, 0, 0, 1);
    opacity: 1;
    background: rgba(238, 238, 238, 0.3);
    transition: all 0.2s ease;

    img {
      width: 100%;
      //height: 100%;
    }

    &.swiper-pagination-bullet-active {
      opacity: 1;
      color: white;
      background: #f89227;
    }
  }
}
</style>
