<template>
    <div class="eight-item">
        <div class="top" :style="{ backgroundImage: `url(${data.img})` }"></div>
        <div class="bottom">
            <div class="logo-wrap">
                <img :src="data.logo" alt="" />
            </div>
            <p class="title">{{ data.title }}</p>
            <p class="des">{{ data.des }}</p>
            <a :href="data.url" v-if="!data.video"  target="_blank" :download="data.url">
                 <!-- <div class="download"  >
                    <img :src="data.video ? require('../../assets/img/video.svg') : require('../../assets/img/download-icon.png')" alt="" />
                    <span >资料下载</span>
                </div> -->
            </a>
            <div class="download" v-else  @click="viewVideo(data)">
                <img :src="data.video ? require('../../assets/img/video.svg') : require('../../assets/img/download-icon.png')" alt="" />
                <span>观看视频</span>
            </div>
            <!-- <a href="https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/vrbee_intro_0428.pdf" target="_blank">
                <div class="download vrbee" v-if="data.type === 'vrbee'">
                    <div class="mask"></div>
                    <img src="../../assets/img/download-icon.png" alt="" />
                    <span>资料下载</span>
                </div>
            </a> -->
            <!-- <a href="https://app.shb.ltd/p/109299#/login?redirect=%2FchooseEvent" target="_blank">
                <div class="download octopus" v-if="data.type === 'octopus'">
                    <div class="mask"></div>
                    <img src="../../assets/img/try-logo.png" alt="" />
                    <span>申请试用</span>
                </div>
            </a> -->
            <a :href="data.link" target="_blank">
                <div class="download city" v-if="data.type === '3dcity'">
                    <div class="mask"></div>
                    <img src="../../assets/img/home/4group/vrbox-try.png" alt="" />
                    <span>立即体验</span>
                </div>
            </a>
            <!-- <a href="https://ideavr.obs.cn-east-2.myhuaweicloud.com/gdiweb/LinkXR4.0.0%E5%AE%89%E8%A3%85%E5%8C%85%E5%8F%8ASDK.zip" target="_blank">
                <div class="download linkvr" v-if="data.type === 'linkvr'">
                    <div class="mask"></div>
                    <img src="../../assets/img/download-icon.png" alt="" />
                    <span>资料下载</span>
                </div>
            </a> -->
            <!-- <a href="https://app.shb.ltd/p/109299#/login?redirect=%2FchooseEvent" target="_blank">
                <div class="download flyvr" v-if="data.type === 'flyvr'">
                    <div class="mask"></div>
                    <img src="../../assets/img/try-logo.png" alt="" />
                    <span>申请试用</span>
                </div>
            </a> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EightGroupItem',
        props: {
            data: {
                type: Object,
                default() {
                    return {}
                },
            },
        },
        methods: {
            viewVideo(data) {
                layui.use('layer', function() {
                    const layer = layui.layer
                    layer.open({
                        type: 1,
                        title: false,
                        area: '60%',
                        scrollbar: false,
                        content: `<video autoplay controls src="${data.videoUrl}" style="width: 100%;outline: none">`,
                    })
                })
            },
        },
    }
</script>

<style scoped lang="scss">
    .eight-item {
        width: 345px;
        height: 520px;
        overflow: hidden;
        background-color: #292929;
        transition: all 0.3s;

        &:hover {
            border-color: rgba(0, 0, 0, 0.09);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
        }

        .top {
            width: 100%;
            height: 230px;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .bottom {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 36px;

            .logo-wrap {
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .title {
                margin-top: 14px;
                font-family: arial !important;
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                color: #ffffff;
            }

            .des {
                //margin-top: 3px;
                font-family: arial !important;
                font-style: normal;
                //font-weight: bold;
                font-size: 18px;
                color: #ffffff;
            }

            .download {
                width: 140px;
                height: 32px;
                background: transparent;
                border-radius: 100px;
                border: 1px solid #bbbbbb;
                border-radius: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: all 0.2s ease;
                margin-top: 36px;
                transition: all 0.2s;
                //&:hover {
                //  opacity: 0.95;
                //}
                &:hover {
                    border: 1px solid #ffffff;
                }

                &:active {
                    border: 1px solid #bcbcbc;
                    opacity: 0.8;
                }

                &.city {
                    position: relative;
                    margin-top: 15px;
                    border: none;
                    background: linear-gradient(312.85deg, #f89227 19.95%, #f8c71a 74.99%);

                    .mask {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        border-radius: 100px;
                        transition: all 0.2s;
                        background: rgba(0, 0, 0, 0);
                    }

                    &:hover {
                        .mask {
                            background: rgba(255, 255, 255, 0.2);
                        }
                    }

                    &:active {
                        .mask {
                            background: rgba(0, 0, 0, 0.2);
                        }
                    }
                }

                &.linkvr {
                    @extend .city;
                    background: linear-gradient(312.04deg, #004aa2 21.11%, #00a4fd 77.09%);
                }

                &.octopus {
                    @extend .city;
                    background: linear-gradient(312.04deg, #004f67 21.11%, #33c6cf 77.09%);
                }

                &.vrbee {
                    @extend .city;
                    background: linear-gradient(rgba(245, 142, 33, 1),rgba(248, 199, 26, 1));
                }

                &.flyvr {
                    @extend .city;
                    background: linear-gradient(312.04deg, #19b8be 21.11%, #67f1f7 77.09%);
                }

                img {
                    width: 12px;
                }

                span {
                    margin-left: 7px;
                    font-family: arial !important;
                    font-style: normal;
                    //font-weight: 500;
                    font-size: 14px;
                    color: #ffffff;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
</style>
